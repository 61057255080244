<template>
  <div id="app">
    <h1>To-Do List</h1>
    <ErrorMessage v-if="error" :message="error"></ErrorMessage>
    <to-do-form @todo-added="addToDo"></to-do-form>
    <h2 id="list-summary" ref="listSummary" tabindex="-1">{{ listSummary }}</h2>
    <ul aria-labelledby="list-summary" class="stack-large">
      <li v-for="item in ToDoItems" :key="item.id">
        <to-do-item
          :label="item.label"
          :done="item.done"
          :id="item.id"
          @checkbox-changed="updateDoneStatus(item.id)"
          @item-deleted="deleteToDo(item.id)"
          @item-edited="editToDo(item.id, $event)">
        </to-do-item>
      </li>
    </ul>
  </div>
</template>

<script>
import ToDoItem from "./components/ToDoItem.vue";
import ToDoForm from "./components/ToDoForm.vue";
import ErrorMessage from "./components/ErrorMessage.vue";
import uniqueId from "lodash.uniqueid";

export default {
  name: "app",
  components: {
    ToDoItem,
    ToDoForm,
    ErrorMessage,
  },
  data() {
    return {
      ToDoItems: [],
      isInitialLoad: true,
      apiKey: '',
      apiID: '',
      error: '',
    };
  },
  methods: {
    addToDo(toDoLabel) {
      if(toDoLabel === ''){
        this.error = 'Please add some text to the todo item';
        return;
      }

      this.ToDoItems.push({
        id: uniqueId("todo-"),
        label: toDoLabel,
        done: false,
      });
    },
    updateDoneStatus(toDoId) {
      const toDoToUpdate = this.ToDoItems.find((item) => item.id === toDoId);
      toDoToUpdate.done = !toDoToUpdate.done;
    },
    deleteToDo(toDoId) {
      const itemIndex = this.ToDoItems.findIndex((item) => item.id === toDoId);
      this.ToDoItems.splice(itemIndex, 1);
      this.$refs.listSummary.focus();
    },
    editToDo(toDoId, newLabel) {
      const toDoToEdit = this.ToDoItems.find((item) => item.id === toDoId);
      toDoToEdit.label = newLabel;
    },
  },
  computed: {
    listSummary() {
      const numberFinishedItems = this.ToDoItems.filter(
        (item) => item.done
      ).length;
      return `${numberFinishedItems} out of ${this.ToDoItems.length} items completed`;
    },
  },
  mounted(){
    //calling the api data
    this.apiKey = process.env.VUE_APP_API_URL;
    this.apiID = process.env.VUE_APP_API_ID;

    // Check if hasChanged exists in the cache
    caches.open('my-cache').then(cache => {
      cache.match('hasChanged').then(response => {
        if (!response) {
          // Set hasChanged to false if it doesn't exist
          const hasChangedResponse = new Response('false');
          cache.put('hasChanged', hasChangedResponse);
        }
      });
    });

    //using axios to get the data
    this.axios.get(this.apiKey+'fetch')
    .then(response => {
      //breaking down the data to an array for the Todo items
      this.ToDoItems = response.data[0].data;

      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: 'CACHE_URL',
          url: this.apiKey+'fetch',
          response: JSON.stringify(this.ToDoItems)
        });
      }

      //now we have the items we need to make sure the watcher is not watching this first instance.
      this.$nextTick(() => {
        //we set the initialLoad to false to let the watcher know it is not the on mounted data
        this.isInitialLoad = false;
      }).catch(error => {
        this.error(error);
      });
    }).catch(() => {
      caches.match(this.apiKey + 'fetch').then(response => {
        if (response) {
          response.json().then(data => {
            this.ToDoItems = data;
          });
        }
      });
    });
  },
  watch: {
    error: {
      handler(newVal) {
        // Reset the error message after 5 seconds
        if (newVal !== '') {
          setTimeout(() => {
            this.error = '';
          }, 5000);
        }
      }
    },
    ToDoItems: {
      handler(newVal) {
        //making sure it is not the initial load data being processed
        if (!this.isInitialLoad) {
          // Update cached data
          caches.open('my-cache').then(cache => {
            const response = new Response(JSON.stringify(newVal));
            cache.put(this.apiKey + 'fetch', response);
          });

          // Set the cached changes binary (always update when ToDoItems change)
          caches.open('my-cache').then(cache => {
            const response = new Response('true');
            cache.put('hasChanged', response);
          });

          // Send the data to the API if online
          if (navigator.onLine) {
            // Create the body for the API request
            const body = {
              "_id": this.apiID,
              "data": newVal
            };

            // Send the data to the API
            this.axios.post(this.apiKey + 'update', body)
              .catch(error => {
                this.error(error);
              });
          }
        }
      },
      deep: true
    }
  }
};
</script>

<style>
/* Global styles */
.btn {
  padding: 0.8rem 1rem 0.7rem;
  border: 0.2rem solid #4d4d4d;
  cursor: pointer;
  text-transform: capitalize;
}
.btn__danger {
  color: #fff;
  background-color: #ca3c3c;
  border-color: #bd2130;
}
.btn__filter {
  border-color: lightgrey;
}
.btn__danger:focus {
  outline-color: #c82333;
}
.btn__primary {
  color: #fff;
  background-color: #000;
}
.btn-group {
  display: flex;
  justify-content: space-between;
}
.btn-group > * {
  flex: 1 1 auto;
}
.btn-group > * + * {
  margin-left: 0.8rem;
}
.label-wrapper {
  margin: 0;
  flex: 0 0 100%;
  text-align: center;
}
[class*="__lg"] {
  display: inline-block;
  width: 100%;
  font-size: 1.9rem;
}
[class*="__lg"]:not(:last-child) {
  margin-bottom: 1rem;
}
@media screen and (min-width: 620px) {
  [class*="__lg"] {
    font-size: 2.4rem;
  }
}
.visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}
[class*="stack"] > * {
  margin-top: 0;
  margin-bottom: 0;
}
.stack-small > * + * {
  margin-top: 1.25rem;
}
.stack-large > * + * {
  margin-top: 2.5rem;
}
@media screen and (min-width: 550px) {
  .stack-small > * + * {
    margin-top: 1.4rem;
  }
  .stack-large > * + * {
    margin-top: 2.8rem;
  }
}
/* End global styles */
#app {
  background: #fff;
  margin: 2rem 0 4rem 0;
  padding: 1rem;
  padding-top: 0;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2.5rem 5rem 0 rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 550px) {
  #app {
    padding: 4rem;
  }
}
#app > * {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}
#app > form {
  max-width: 100%;
}
#app h1 {
  display: block;
  min-width: 100%;
  width: 100%;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
}
</style>
