<template>
    <div class="errormessage">
        {{ message }}
    </div>
</template>
  
<script>
export default {
    props: {
        message: { required: true, type: String },
    },
};
</script>

<style scoped>
    .errormessage{
        background: rgb(177, 15, 15);
        color:white;
        padding:8px 16px;
        margin:20px 0;
    }
</style>
  